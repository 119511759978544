.production {
  &__head {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    padding-bottom: 25px;
  }
  &__title {
    font-size: 1em;
    padding-right: 1em;
    line-height: 1.1em;
  }
  &__label {
    white-space: nowrap;
    transform: translateY(0.1em);
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    height: 2em;
    background-color: #004b26;
    border-radius: 2em;
    font-size: 1em;
    color: #fff;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px -30px -12px;
  }
}
.production-block {
  width: 16.66%;
  padding: 0 12px 30px 12px;
  &__image-container {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      padding-top: 229%
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #108f43;
      z-index: 2;
      opacity: 0;
      transition: opacity .3s;
    }
    &:hover {
      &:after {
        opacity: 0.6;
      }
      img {
        opacity: 0.9;
      }
    }
  }
  &__image {
    display: block;
    position: absolute;
    height: 100%;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: opacity .3s;
  }
  &__title-container {
    padding-top: 35px;
  }
}

@media screen and(max-width: 1200px) {
  .production__head {
    font-size: 24px;
  }
  .production-block {
    width: 33.33%;
    &__image-container:before {
      padding-top: 100%;
    }
    &__title-container {
      padding-top: 15px;
    }
  }
}
@media screen and(max-width: 992px) {
  .production__label {
    padding: 0 30px;
  }
}
@media screen and(max-width: 768px) {
  .production__head {
    flex-direction: column;
    align-items: center;
  }
  .production-block {
    width: 50%;
  }
  .production__title {
    display: block;
    text-align: center;
    padding: 0 0 0.5em 0;
    line-height: 1.3em;
  }
}
@media screen and(max-width: 460px) {
  .production-block {
    width: 100%;
  }
  .production__head {
    font-size: 20px;
  }
}