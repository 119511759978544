.about {
  background-color: #79c798;
  color: #195b3a;
  .container {
    padding: 0;
  }
  &__container {
    display: flex;
  }
  &__section {
    width: 55%;
    flex-shrink: 0;
    position: relative;
  }
  &__image-container {
    overflow: hidden;
    position: relative;
    &:before {
      content: '';
      display: block;
      padding-top: 84%;
      overflow: hidden;
    }
  }
  &__image {
    display: block;
    position: absolute;
    height: 100%;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  &__label {
    position: absolute;
    text-transform: uppercase;
    color: #000;
    padding: 18px 50px 18px 90px;
    z-index: 2;
    bottom: 30px;
    right: 28px;
    display: block;
    background: #fff;
    transform: translateX(100%);
    border-radius: 30px;
    font-size: 18px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 17px;
      height: 23px;
      width: 23px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #004b26;
      border-radius: 100%;
    }
  }
  &__text-container {
    padding: 30px;
  }
  &__title {
    font-size: 30px;
    font-weight: 800;
  }
  &__text {
    font-size: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and(max-width: 992px) {
  .about {
    &__title {
      font-size: 24px;
    }
    &__text {
      font-size: 18px;
    }
    &__label {
      right: -28px;
      transform: translateX(0);
      padding: 18px 90px 18px 50px;
      &:before {
        left: auto;
        right: 17px;
      }
    }
  }
}
@media screen and(max-width: 768px) {
  .about {
    &__section {
      width: 50%;
    }
    &__label {
      display: none;
    }
    &__text-container {
      padding: 15px;
    }
    &__section {
      padding: 15px 0 15px 15px;
    }
  }
}
@media screen and(max-width: 576px) {
  .about {
    &__container {
      flex-direction: column;
    }
    &__section {
      width: 100%;
      padding: 15px 15px 0 15px;
    }
    &__image-container:before {
      padding-top: 55%;
    }
    &__title {
      font-size: 20px;
    }
    &__text {
      font-size: 16px;
    }
  }
}