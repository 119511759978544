.header {
  background-color: #fff;
  &__container {
    padding: 55px 0 0 0;
  }
  &__section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &:not(:first-child) {
      border-bottom: 1px solid #004b26;
    }
  }
  &__sub-title {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 18px;
    color: #004b26;
    padding-bottom: 0.2em;
  }
  .nav {
    padding: 19px 0;
  }
}

.logo {
  font-size: 30px;
  flex-shrink: 0;
  &__link {
    display: flex;
    align-items: flex-start;
    text-decoration: none;
  }
  &__image {
    width: 4em;
    height: auto;
    display: block;
  }
  &__title {
    font-size: 1em;
    color: #004b26;
    line-height: 1em;
    display: block;
  }
  &__text {
    padding-left: 0.85em;
    transform: translateY(-0.1em);
  }
  &__slogan {
    font-size: 0.9em;
    color: #000101;
    line-height: 1.1em;
  }
}
.buttons {
  width: 100%;
  padding-left: 80px;
  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }
  &__item {
    flex-shrink: 0;
    &:not(:last-child) {
      padding-right: 25px;
      flex-shrink: 1;
      width: 100%;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    background-color: #79c798;
    text-decoration: none;
    transition: background-color .3s;
    color: #fff;
    text-transform: uppercase;
    font-size: 30px;
    border: 0;
    appearance: none;
    cursor: pointer;
    padding: 0;
    &:hover {
      background-color: #004b26;
    }
    &_search {
      background: #79c798 url('../img/icons/ico-magnifier.png') no-repeat;
      background-size: 80% 80%;
      background-position: 65% 65%;
      position: absolute;
      right: 0;
      top: 0;
      button {
        padding: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        background: transparent;
        position: relative;
        z-index: 1;
        cursor: pointer;
      }
    }
  }
  &__form {
    position: relative;
    height: 55px;
    display: flex;
  }
  &__input {
    border: 1px solid #959595;
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 50px;
    font-size: 24px;
    padding: 13px 0 13px 25px;
    width: 100%;
    display: none;
    &::placeholder {
      color: #000101;
    }
    &:focus {
      &::placeholder {
        color: transparent;
      }
    }
  }
}
.mobile-button {
  transition: .3s;
  width: 40px;
  height: 28px;
  z-index: 15;
  display: none;
  position: relative;
  flex-shrink: 0;
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 40px;
    left: 0;
    position: absolute;
    top: 50%;
    margin-top: -2px;
    border-radius: 4px;
    background-color: #217e46;
    transition: .3s;
    &:before,
    &:after {
      display: block;
      content: "";
      height: 4px;
      border-radius: 2px;
      width: 100%;
      background-color: #217e46;
      position: absolute;
      transition: .3s;
      left: 0;
    }
    &:before {
      top: -12px;
    }
    &:after {
      bottom: -12px;
    }
  }
  &_active {
    span {
      background-color: transparent !important;
      &:before {
        transform: rotate(45deg);
        top: 0px;
      }
      &:after {
        transform: rotate(-45deg);
        bottom: 0px;
      }
    }
  }
}
@media screen and(max-width: 1200px) {
  .buttons {
    padding-left: 55px;
  }
}
@media screen and(max-width: 992px) {
  .header {
    .logo {
      font-size: 24px;
    }
    &__sub-title {
      padding: 8px 0 5px 0;
      font-size: 16px;
    }
    .buttons__link {
      height: 44px;
      width: 44px;
      font-size: 24px;
    }
    .buttons__form {
      height: 44px;
    }
    .buttons__input {
      font-size: 18px;
      padding: 10px 0 10px 20px;
    }
    .nav__link {
      font-size: 16px;
    }
  }
}
@media screen and(max-width: 768px) {
  .header {
    &__container {
      padding: 20px 0 0 0;
    }
    &__section {
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(1) {
        flex-wrap: wrap;
      }
    }
    .logo {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
    }
    .buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-top: 1px solid #004b26;
      &__form {
        height: 40px;
      }
      &__input {
        font-size: 16px;
        padding-left: 45px;
      }
      &__item {
        &:not(:last-child) {
          order: 2;
          padding: 0 0 0 20px;
        }
      }
      &__link_search {
        right: auto;
        left: 0;
      }
      &__list {
        padding-right: 20px;
      }
      &__link {
        width: 40px;
        height: 40px;
        font-size: 20px;
      }
    }
    .mobile-button {
      display: block;
    }
    .nav {
      padding: 0;
      display: none;
      &__list {
        display: block;
        padding: 15px 0 20px 0;
      }
      &__item {
        padding: 0;
      }
      &__link {
        display: block;
        text-align: center;
        padding: 10px 0;
        font-size: 18px;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
}
@media screen and(max-width: 576px) {
  .header {
    .logo {
      &__link {
        font-size: 18px;
        flex-direction: column;
        align-items: center;
      }
      &__text {
        padding: 0.5em 0 0 0;
        text-align: center;
      }
      &__title {
        font-size: 0.95em;
      }
    }
  }
}