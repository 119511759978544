@import "./partials/normalize";
@import "./partials/fonts";

body {
  text-rendering: optimizeSpeed;
  font: 14px 'MontserratRegular', sans-serif;
  min-width: 320px;
  color: #000101;
  position: relative;
  overflow-x: hidden;
  line-height: 1.5;
}
a {
  &:hover {
    text-decoration: none;
  }
}

p {
  margin: 0;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
strong {
  font-weight: 500;
}
h1, h2, h3, h4, h5 {
  margin: 40px 0 12px 0;
  &:first-child {
    margin-top: 0;
  }
}
h3 {
  font-size: 20px;
  font-weight: 500;
}
h5 {
  font-size: 14px;
  font-weight: 500;
}
.float-right {
  float: right;
  padding-left: 30px;
}
.align-left {
  text-align: left;
}
.uppercase {
  text-transform: uppercase;
}
.textarea {
  text-align: justify;
  text-align-last: left;
  h1, h2, h3, h4, h5 {
    max-width: 660px;
  }
  p {
    max-width: 660px;
  }
  img {
    width: auto;
    padding-bottom: 15px;
    max-width: 100%;
  }
  .list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 660px;
    margin: 20px 0;
    li {
      margin: 12px 0;
      display: block;
      &:not(:first-child) {
        &:before {
          content: '•';
          padding-right: 5px;
        }
      }
    }
    &_style2 {
      li {
        display: flex;
        &:not(:first-child) {
          &:before {
            content: '-';
            padding-right: 10px;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
.columns {
  column-count: 2;
  column-gap: 40px;
  max-width: 700px;
}


.page {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  &__title {
    margin: 0;
    font-size: 32px;
    line-height: 1.2em;
    font-weight: normal;
    display: block;
    padding-bottom: 25px;
    margin-bottom: 25px;
    position: relative;
    text-transform: uppercase;
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-width: 400px;
      height: 1px;
      background-color: #004b26;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.content {
  padding: 30px 0 60px 0;
  &__container {
    display: flex;
    .main {
      width: calc(100% - 260px);
    }
  }
}
.container {
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
}
.main {
}
.aside {
  width: 260px;
  flex-shrink: 0;
  padding-right: 30px;
}
.drina {
  font-family: 'Drina';
}
.title {
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1.1em;
  display: flex;
  white-space: nowrap;
  padding-bottom: 25px;
  &:after {
    content: '';
    display: block;
    flex: 1 1 100%;
    height: 1px;
    background-color: #004b26;
    margin-left: 0.7em;
    transform: translateY(0.25em);
  }
}
.block {
  padding: 50px 0 0 0;
}
.nav {
  width: 100%;
  &__list {
    display: flex;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: space-between;
  }
  &__item {
    &:not(:last-child) {
      padding-right: 10px;
    }
  }
  &__link {
    text-decoration: none;
    font-size: 18px;
    text-transform: uppercase;
    color: #000101;
    white-space: nowrap;
  }
}
.socials {
  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }
  &__link {
    display: block;
    width: 30px;
    height: 30px;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
    img{ 
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
.cover-box {
  position: relative;
  img {
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.wh {
  height: auto;
  width: 100%;
}
.ww {
  height: 100%;
  width: auto;
}
.certificates {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px;
  &__block {
    padding: 12px 7px;
    width: 33.33%;
    &_big {
      width: 66.66%;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
@media screen and(max-width: 1200px) {
  .title {
    font-size: 24px;
  }
}
@media screen and(max-width: 768px) {
  .block {
    padding: 45px 0 0 0;
  }
  .content__container {
    flex-direction: column;
  }
  .aside {
    width: 100%;
    padding: 0;
  }
  .aside-nav {
    display: none;
    padding-bottom: 30px;
  }
  .main {
    width: 100% !important;
  }
}
@media screen and(max-width: 576px) {
  .certificates__block {
    width: 50%;
    &_big {
      width: 100%;
    }
  }
  .page__title {
    font-size: 24px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .float-right {
    padding: 0;
    width: 100%;
    float: none;
  }
  .textarea img {
    width: 100%;
  }
}
@media screen and(max-width: 460px) {
  .title {
    font-size: 20px;
  }
  .columns {
    column-count: 1;
  }
}

@import "./partials/normalize";
@import "./partials/header";
@import "./partials/footer";
@import "./partials/about";
@import "./partials/production";
@import "./partials/news";
@import "./partials/aside";
@import "./partials/geography";
@import "./partials/gallery";


