.geography {
  &__container {
    background-color: #ededed;
    padding: 12px 0;
  }
  &__image-container {
    position: relative;
    &:before {
      content: '';
      display: block;
      padding-top: 43%;
    }
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}